import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_936E40VvcyMaMeta } from "/vercel/path0/pages/news/[item].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as roomstL5659IyVgMeta } from "/vercel/path0/pages/rooms.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___nl",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___fr",
    path: "/fr/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "contact___nl",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "faq___nl",
    path: "/veelgestelde-vragen",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___fr",
    path: "/fr/questions-frequemment-posees",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "gallery___nl",
    path: "/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___en",
    path: "/en/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___fr",
    path: "/fr/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "highlights___nl",
    path: "/omgeving",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___en",
    path: "/en/location",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___fr",
    path: "/fr/emplacement",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news-item___nl",
    path: "/nieuws/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: "news-item___en",
    path: "/en/news/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: "news-item___fr",
    path: "/fr/actualites/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: "news___nl",
    path: "/nieuws",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___fr",
    path: "/fr/actualites",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "packages-item___nl",
    path: "/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___en",
    path: "/en/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___fr",
    path: "/fr/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages___nl",
    path: "/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___en",
    path: "/en/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___fr",
    path: "/fr/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "reviews___nl",
    path: "/beoordelingen",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___fr",
    path: "/fr/revues",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "rooms___nl",
    path: "/kamers",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___en",
    path: "/en/rooms",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___fr",
    path: "/fr/chambres",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___nl",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  }
]